import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import LazyLoad from 'react-lazyload'
import Img from '../../../../../../src/components/shared/Img'
import { TRUSTPILOT_URL } from '../../../../../common/constants'
import TrustBoxItem from './TrustBoxItem'
import { sliderSettings } from '../consts'
import styles from './index.module.scss'
import { useAnalytics } from 'src/common/analytics'
import { useIntersectionObserver } from 'src/common/hooks/useIntersectionObserver'
import { useTrustMarkers } from 'src/common/trust-markers'

const SlickSlider = dynamic(() => import('../../SlickSlider'))

const TrustReviewsComponent = ({ reviews, itemClassname }) =>
  reviews.map((user, id) => (
    <TrustBoxItem
      key={id}
      id={user.id}
      date={user.createdAt}
      stars={user.stars}
      title={user.title}
      text={user.text}
      consumer={user.consumer}
      itemClassname={itemClassname}
    />
  ))
const TrustBox = ({
  reviews,
  itemClassname,
  trustBoxClassName,
  slickSliderSettings,
}) => {
  const { getTrustMarkerById } = useTrustMarkers()
  const trustPilot = getTrustMarkerById('truspilot')
  const { track } = useAnalytics()
  const { ref: trustBoxRef } = useIntersectionObserver({
    threshold: 0.5,
    freezeOnceVisible: true,
    onChange: (isIntersecting) => {
      if (isIntersecting) {
        const data = {
          nav_link_section: 'Testimonials Page',
          click_type: 'Section View',
          click_id: 'Debt relief Reviews',
          click_url: '/',
          click_text: 'FDR-Web | Debt relief Reviews',
          track_event: 'section_view',
          event_type: 'track',
          additional_info: { section: 'trustpilot-box' },
        }
        track(data, {}, 'section_view')
      }
    },
  })

  const handleTracking = useCallback(
    (section) => {
      track(
        {
          additional_info: { section: section },
        },
        {},
        'click'
      )
    },
    [track]
  )

  const handleClickReview = useCallback(
    (id) => {
      handleTracking('trustbox-item')
      window.open(`https://www.trustpilot.com/reviews/${id}`)
    },
    [handleTracking]
  )

  const openTrustPilot = useCallback(() => {
    handleTracking('trustpilot-logo')
    window.open(TRUSTPILOT_URL)
  }, [handleTracking])

  let reviewsNumber = trustPilot.totalReview
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  return (
    <div className={`${trustBoxClassName}`} id="trustpilot-box">
      <div className="w-content">
        <div ref={trustBoxRef} id="trust-box-title" className="pb-8 pt-16">
          <h3 className="px-6 text-center font-bold tracking-widest text-gray-300 lg:text-14">
            FREEDOM DEBT RELIEF REVIEWS
          </h3>
          <h2 className="px-6 text-center text-28 font-bold  lg:text-37">
            Trusted by over 1 million clients
          </h2>
        </div>
        <div>
          <LazyLoad
            placeholder={
              <TrustReviewsComponent
                reviews={reviews}
                itemClassname={itemClassname}
              />
            }
          >
            <SlickSlider
              sliderSettings={slickSliderSettings}
              dotsClassName="pb-8"
              classNames={styles.slider}
              name="trustpilot-reviews"
            >
              {reviews.map((user, id) => (
                <TrustBoxItem
                  key={id}
                  id={user.id}
                  stars={user.stars}
                  text={user.text}
                  title={user.title}
                  date={user.createdAt}
                  consumer={user.consumer}
                  itemClassname={itemClassname}
                  handleClick={handleClickReview}
                />
              ))}
            </SlickSlider>
          </LazyLoad>
        </div>
        <div className="text-center">
          <p data-testid="rates" className="ml-2">
            Rated {Number(trustPilot.rating)} / 5 based on {reviewsNumber}
            <span className="block lg:inline">
              {' '}
              reviews Showing our 4 & 5 star reviews.
            </span>
          </p>
        </div>
        <div
          onClick={openTrustPilot}
          className="mx-auto mt-4 w-36 cursor-pointer pb-12"
        >
          <Img
            src="/next-assets/trustpilot/trustpilot-logo.svg"
            alt="trustpilot logo"
            className="h-10 w-auto"
          />
          <Img
            src="/next-assets/trustpilot/trustpilot-rating.svg"
            alt="trustpilot rating"
            className="h-6 w-auto"
          />
        </div>
      </div>
    </div>
  )
}

TrustBox.defaultProps = {
  reviews: [],
  trustBoxClassName: 'bg-green-110',
  itemClassname: 'bg-white-110',
  slickSliderSettings: sliderSettings,
}

TrustBox.propTypes = {
  reviews: PropTypes.array,
  itemClassname: PropTypes.string,
  trustBoxClassName: PropTypes.string,
  slickSliderSettings: PropTypes.object,
}

export default TrustBox
